<template>
  <div style="position: relative;">

    <v-btn v-if="page!=='footer'" @click="openModal()" :class="styleClass"> {{ textBtn }}</v-btn>
    <a v-if="page==='footer'" @click="openModal()" :class="styleClass"  style="text-decoration: none; cursor: pointer;">{{ textBtn }}</a><br>
    <v-row v-if="showModal" class="custom-dialog-overlay" @click.self="handleInsideClick">
      <div class="custom-dialog-content">
        <div class="custom-dialog-scrollable px-2">
          <slot>
            <v-card-text class="p-0">
              <v-icon class="iconClose  " color="white" icon="mdi-window-close" size="x-large" @click="closeModal"
                      :disabled="overlay"/>
              <v-row justify="center">
                <v-col cols="12" sm="12" md="12" class="px-0">
                  <v-img :src="require('../../../public/img/app/logoSinntecfondoBlanco.png')" class="mx-16 my-0"/>
                  <v-form v-model="valid" fast-fail @submit.prevent ref="form">
                    <v-text-field
                        rounded
                        class="mt-16 mx-4 inputModal"
                        v-model.trim="name"
                        :rules="nameRules"
                        :counter="50"
                        label="Ingresa tu nombre*"
                        hide-details="false"
                        required
                    ></v-text-field>
                    <v-text-field
                        rounded
                        class="mt-4 mx-4 inputModal"
                        v-model.trim="email"
                        :rules="emailRules"
                        label="Correo*"
                        hide-details="false"
                        required
                    ></v-text-field>
                    <v-text-field
                        rounded
                        class="mt-4 mx-4 inputModal"
                        v-model.trim="empresaForm"
                        :rules="enterpriseRules"
                        :counter="20"
                        label="Empresa*"
                        hide-details="false"
                        required
                    ></v-text-field>
                    <v-text-field
                        rounded
                        class="mt-4 mx-4 inputModal"
                        v-model.trim="telefonoForm"
                        :rules="telephoneRules"
                        :counter="10"
                        label="Teléfono (10 digitos)*"
                        hide-details="false"
                        required
                    ></v-text-field>
                    <v-select
                        rounded
                        v-model="puestoEmp"
                        class="mt-4 mx-4 mb-3 inputModal"
                        item-title="name"
                        item-value="id"
                        :items="puestos"
                        label="Selecciona tu puesto*"
                        hide-details="false"
                        required
                    ></v-select>
                    <p class="mx-4 textModal">¿Cuentas con los requerimiento técnicos y de negocio del proyecto?</p>
                    <v-radio-group
                        class="mx-4 textModal"
                        v-model.number="selectradioRequer"
                        inline
                    >
                      <v-radio
                          label="No"
                          name="presupuesto"
                          value="0"
                      />
                      <v-radio
                          label="Si"
                          name="presupuesto"
                          value="1"
                          :click="showRequeriments()"
                      />
                    </v-radio-group>

                    <v-select
                        rounded
                        class="inputModal mb-3"
                        hide-details="false"
                        v-model="tipoProyecto"
                        v-if="requeriments"
                        item-title="name"
                        item-value="id"
                        :items="proyectos"
                        label="Elige el tipo de proyecto a desarrollar*"
                        required
                    ></v-select>
                    <v-select
                        hide-details="false"
                        class="inputModal mb-3"
                        v-model="nivelProyecto"
                        v-if="requeriments"
                        item-title="name"
                        item-value="id"
                        :items="niveles"
                        label="Elige la etapa actual del proyecto*"
                        required
                    ></v-select>
                    <v-select
                        rounded
                        hide-details="false"
                        class="inputModal mb-3"
                        v-model="presupuestomxn"
                        v-if="requeriments"
                        item-title="name"
                        item-value="id"
                        :items="balance"
                        label="Elige el rango del presupuesto*"
                        required
                    ></v-select>
                    <p class="mx-4 textModal">¿El proyecto se ha intentado realizar anteriormente?</p>
                    <v-radio-group
                        class="mx-4 textModal"
                        v-model="selectradioRealizarProy"
                        inline
                    >
                      <v-radio
                          label="No"
                          value='0'
                      ></v-radio>
                      <v-radio
                          label="Si"
                          value='1'
                      ></v-radio>
                    </v-radio-group>
                    <v-textarea
                        hide-details
                        v-model="description"
                        :rules="descripcionForm"
                        class="mx-4 descriptionProyect"
                        label="Descripción breve del servicio deseado:*"
                        required
                    ></v-textarea>
                    <div>
                      <vueReCaptcha
                          class="recaptcha__modal"
                          v-show="showRecaptcha"
                          size="normal"
                          theme="light"
                          :hl="'es-419'"
                          :loading-timeout="loadingTimeout"
                          :sitekey="siteKey"
                          @verify="recaptchaVerified"
                          @expire="recaptchaExpired"
                          @fail="recaptchaFailed"
                          @error="recaptchaError"
                          ref="vueRecaptcha">
                        <p>Completa la verificación</p>
                      </vueReCaptcha>
                    </div>
                    <v-btn
                        :loading="overlay"
                        :disabled="!ReCaptacha || !valid"
                        rounded
                        @click="sendform()"
                        type="submit"
                        block
                        class="btnsendForm mt-3"
                    >
                      Enviar solicitud
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </slot>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import swal from 'sweetalert';
import vueReCaptcha from 'vue3-recaptcha2'
import AppConfig from "@/Constants/AppConfig";


export default {
  components: {
    vueReCaptcha
  },
  data: () => ({
    selectradioRequer: '0',
    selectradioRealizarProy: '0',
    requeriments: false,
    result: null,
    stateModal: true,
    showModal: false,
    overlay: false,

    // ReCaptcha
    ReCaptacha: false,
    showRecaptcha: true,
    loadingTimeout: 10000, //10 seconds
    siteKey: AppConfig.sitekey,
    domain: AppConfig.domain,
    addsKey: AppConfig.googleAdds,

    // Variables para el formulario
    nombre: '',
    correo: '',
    empresa: '',
    telefono: '',
    puestoEmp: '',
    descripcion: '',
    tipoProyecto: '',
    nivelProyecto: '',
    presupuestomxn: '',
    procedencia: '',
    paginaOrigen: '',
    id_call_action: '',

    // Puesto para seleccionar
    puestos: [
      {id: 1, name: 'Responsable de área'},
      {id: 2, name: 'Supervisor de área'},
      {id: 3, name: 'Gerente de área'},
      {id: 4, name: 'Director de área'},
      {id: 5, name: 'Director general'},
      {id: 6, name: 'Presidente'},
      {id: 7, name: 'CEO & Founder'},
      {id: 8, name: 'Otro'}
    ],
    proyectos: [
      {id: 1, name: 'Sistema web/ Escritorio'},
      {id: 2, name: 'Sistema web y App iOS/ Android'},
      {id: 3, name: 'App Multiplataforma iOS/ Android'},
      {id: 4, name: 'App Nativa iOS/ Android'},
      {id: 5, name: 'Integración con algun sistema CRM/ ERP'}
    ],
    niveles: [
      {id: 1, name: 'Es un proyecto en idea'},
      {id: 2, name: 'Es un proyecto en estudio de mercado'},
      {id: 3, name: 'Es un proyecto autorizado'},
      {id: 4, name: 'Es un proyecto en cotización para autorización'},
      {id: 5, name: 'Es un proyecto en RFP o Licitación'}
    ],
    balance: [
      {id: 1, name: 'Menos de $200,000.00'},
      {id: 2, name: 'De $200,001.00 a $500,000.00'},
      {id: 3, name: 'De $500,001.00 a $1,500,000.00'},
      {id: 4, name: 'De $1,500,001.00 a 3,000,000.00'},
      {id: 5, name: 'Más de $3,000,000.00'}
    ],
    //Scripts for validation modal
    valid: false,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 50) || 'Name must be less than 50 character',
      v => /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[\sa-zA-ZÀ-ÿ\u00f1\u00d1]+$/g.test(v) || 'Name must be valid'
    ],
    email: '',
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      v => !/(gmail|hotmail|live|yahoo|outlook|icloud)\.com$/i.test(v) || 'Emails from Gmail, Hotmail, Live, Yahoo, Outlook, and iCloud are not allowed',
    ],
    empresaForm: '',
    enterpriseRules: [
      v => !!v || 'Name enterprise is required',
      v => (v && v.length <= 50) || 'Name enterprise must be less than 50 character'
    ],
    telefonoForm: '',
    telephoneRules: [
      v => !!v || 'Number telephone is required',
      v => /^\d{10}$/.test(v) || 'Number telephone must be valid'
    ],
    description: '',
    descripcionForm: [
      v => !!v || 'Description is required',
      v => /^[a-zA-Z0-9À-ÿ\u00f1\u00d1]+(\s*[a-zA-Z0-9À-ÿ\u00f1\u00d1]*)*[\sa-zA-Z0-9À-ÿ\u00f1\u00d1]+$/g.test(v) || 'Description must be valid'
    ],
  }),
  created() {
    this.gtag = window.gtag;
    this.stateModal = this.showModal

    if (this.calltoAction === '21' || this.calltoAction === '113') {
      this.email = this.emailValue;
    }
  },
  props: {
    // showModalApp: {
    //   type: Boolean,
    //   default: false // Asegúrate de que el valor por defecto sea false
    // },
    calltoAction: {
      type: String
    },
    styleClass: {
      type: String
    },
    isTop: {
      type: Boolean,
    },
    emailValue: {
      type: String,
    },
    textBtn: {
      type: String,
    },
    page: {
      type: String,
    }

  },
  // watch:{
  //     validateValue(){
  //         console.log(this.emailValue)
  //         this.email = (this.emailValue == '' ) ? 'Default' : this.emailValue
  //         return this.email
  //     }
  // },
  methods: {
     recaptchaVerified() {
      this.ReCaptacha = true;
     // this.valid=  this.$refs.form.validate();

    },
    recaptchaExpired() {
      this.ReCaptacha = false
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.ReCaptacha = false
      this.$refs.vueRecaptcha.reset();

    },
    recaptchaError() {
      this.ReCaptacha = false
      this.$refs.vueRecaptcha.reset();
    },


    showRequeriments() {
      this.selectradioRequer = document.querySelector('input[name="presupuesto"]:checked')?.value
      this.requeriments = this.selectradioRequer !== '0';
    },
    async sendform() {
      this.overlay = true;
      const formData = {
        name: this.name,
        email: this.email,
        company: this.empresaForm,
        id_size: 1,
        office_phone: this.telefonoForm,
        extension: '',
        phone: this.telefonoForm,
        id_role: this.puestoEmp,
        area: 'Tec',
        description: this.description,
        procedencia: this.calltoAction,
        id_call_action: this.calltoAction,
        id_level: 1,
        id_contact: 1,
        editar: 0,
        personId: 0,
        status: 1,
        //value of radio button
        requirements: this.selectradioRequer,
        id_project_type: this.tipoProyecto,
        id_project_level: this.nivelProyecto,
        id_project_budget_usd: '',
        id_project_budget_mxn: this.presupuestomxn,
        previous_attempt: '',
        page_origin: 'Pagina Beta',

      };

      const credentials = {
        username: '$2y$10$eZP5dc3rYjC9urTUn7OMDO8O40bIQY9LMdAwpKgIwnVuGnaoBZ3Fy',
        password: '$2y$10$fvieRuuBmkari0ot3kVVdutK.kj4/LqazIziiUMxtaChqbpiEDT4C',
        form: formData,
      }

      fetch(this.domain + "/api/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      })
          .then(response => response.json())
          .then((result) => {
            if (result.success) {

              swal({
                title: "Pronto tendrás noticias de nosotros.",
                text: "¡Gracias por confiar en Sinntec!",
                icon: "img/app/sinntecForm.png",
                button: false,
                timer: 3000,
              }).then(() => {
                this.$refs.form.reset();
                this.overlay = false;
                this.closeModal();
                this.googleAdd();
              });


            } else {

              swal({
                title: "No se pudo ingresar tu proyecto",
                text: "Intenta más tarde",
                icon: "error",
                button: false,
                timer: 3000,
              }).then(() => {
                this.overlay = false;
                this.$refs.form.reset();
                this.closeModal();
              });

            }
          })
          .catch((error) => {
            console.log(error)
            swal({
              title: "No se pudo ingresar tu proyecto",
              text: "Intenta más tarde",
              icon: "error",
              button: false,
              timer: 3000,
            }).then(() => {
              this.overlay = false;
              this.closeModal();
              this.$refs.form.reset();
            });
          });
    },
    googleAdd() {
      this.gtag('event', 'conversion', {
        'send_to': this.addsKey ,
      });
    },
    openModal() {
//       // Obtener el ancho y alto de la pantalla
//       const screenWidth = window.innerWidth;
//       const screenHeight = window.innerHeight;
//
// // Imprimir los valores en la consola
//       console.log("Ancho de la pantalla: " + screenWidth + " píxeles");
//       console.log("Alto de la pantalla: " + screenHeight + " píxeles");
      var element = document.querySelector('.editScroll');
      var elementIndex = document.querySelector('.headerZindex');

      var butonCallsm = document.querySelectorAll('.butonCallsm');
      var buttonCall = document.querySelectorAll('.buttonCall');
      if (element) {
        element.style.overflowY = 'clip';
        if (this.isTop) {
          elementIndex.style.zIndex = '-1';
        }
        butonCallsm.forEach(function (element) {
          element.style.zIndex = '-1';
        });
        buttonCall.forEach(function (element) {
          element.style.display = 'none';
        });
        if (this.page === 'home') {
          var carrusell = document.querySelector('.v-carousel__controls');
          var windowCarrusel = document.querySelector('.v-window__controls');
          var tamDemo = document.querySelector('.tamDemo');
          carrusell.style.zIndex = '-1';
          windowCarrusel.style.zIndex = '-1';
          tamDemo.style.zIndex = '-1';
        }
        if (this.page === 'dev') {
          var text = document.querySelector('.solu-development');
          text.style.position = 'static';
          elementIndex.style.zIndex = '0';
          this.$emit('update', false);
        }


      }
      this.showModal = true;

    },
    closeModal() {
      this.ReCaptacha = false;
      this.showModal = false;
      var element = document.querySelector('.editScroll');
      var elementIndex = document.querySelector('.headerZindex');
      var butonCallsm = document.querySelectorAll('.butonCallsm');
      var buttonCall = document.querySelectorAll('.buttonCall');
      elementIndex.style.top = '-10';
      butonCallsm.forEach(function (element) {
        element.style.zIndex = '1';
      });
      buttonCall.forEach(function (element) {
        element.style.display = 'flex';
      });
      if (element) {
        element.style.overflowY = 'scroll';
        if (this.isTop) {
          elementIndex.style.zIndex = '100';
        }
        if (this.page === 'home') {
          var carrusell = document.querySelector('.v-carousel__controls');
          var windowCarrusel = document.querySelector('.v-window__controls');
          var tamDemo = document.querySelector('.tamDemo');
          carrusell.style.zIndex = '1';
          windowCarrusel.style.zIndex = '1';
          tamDemo.style.zIndex = '1';
        }
        if (this.page === 'dev') {
          var text = document.querySelector('.solu-development');
          text.style.position = 'absolute';
          elementIndex.style.zIndex = '100';
          this.$emit('update', true);
        }


      }
    },
    handleInsideClick(event) {
      // Evita que el clic dentro del modal se propague fuera del modal
      event.stopPropagation();
    }
  },
  watch: {
    // showModalApp(val){
    //   console.log(value)
    // }

  }

}
</script>

<style scoped>
@import '../../assets/css/style.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;600;700&display=swap');

.custom-dialog-overlay {

  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.custom-dialog-content {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-height: calc(100% - 48px);
  height: 800px;
  width: 490px;
  max-width: calc(100% - 48px);
  border-radius: 35px;

}

.custom-dialog-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-dialog-scrollable::-webkit-scrollbar {
  width: 0;
}

@media (max-width: 550px) {
  .iconClose {
    position: fixed;
    top: 1px;
    right: 10px;

  }
}

@media (min-width: 551px) {
  .iconClose {
    position: fixed;
    top: 1px;
    right: calc(50% - 275px); /* Calcula el ancho del icono para centrarlo horizontalmente */
  }
}

@media only screen and (min-width: 320px) and (max-width: 1023px) {
  .custom-dialog-scrollable {
    height: 80vh;
  }

}

@media only screen and (min-width: 1024px) and (max-width: 1500px) {
  .custom-dialog-scrollable {
    height: 80vh;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1650px) {
  .custom-dialog-scrollable {
    height: 80vh;
  }
}

@media only screen and (min-width: 1651px) and (max-width: 1800px) {
  .custom-dialog-scrollable {
    height: 80vh;
  }
}

@media only screen and (min-width: 1801px) and (max-width: 2000px) {
  .custom-dialog-scrollable {
    height: 80vh;
  }
}

@media only screen and (min-width: 2001px) and (max-width: 2556px) {
  .custom-dialog-scrollable {
    height: 730px;
  }
}

@media only screen and (min-width: 2556px) {
  .custom-dialog-scrollable {
    height: 725px;
  }
}

.ageDemo {
  background: linear-gradient(204.79deg, #F79433 15.8%, #FF4949 126.22%);
  top: 20px;
  color: #fff !important;
  margin: auto;
  border-radius: 23px;
  text-transform: initial;
}

@media (max-width: 768px) {
  .ageDemo {
    background: linear-gradient(204.79deg, #F79433 15.8%, #FF4949 126.22%);
    width: 100% !important;
    min-width: 100vw;
    color: #fff !important;
    margin: auto;
    top: 0px !important;
    border-radius: 0%;
    text-transform: initial;
  }
}

.btnHomePrincipal {
  border-radius: 23px;
  background: linear-gradient(249.35deg, #F79433 6.62%, #FF4949 95.63%);
  color: #FFFFFF !important;
  font-size: 9px;
  padding: 10px 40px 10px 40px !important;
  text-transform: initial;
  /*margin-left: 128px;*/
  box-shadow: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .btnHomePrincipal {

    font-size: 10px;
    padding: 10px 40px 10px 40px !important;

  }

}

@media only screen and (min-width: 1440px) and (max-width: 1749px) {


  .btnHomePrincipal {

    font-size: 14px;
    padding: 10px 40px 10px 40px !important;

  }


}

@media only screen and (min-width: 1750px) and (max-width: 2560px) {
  .btnHomePrincipal {

    height: 40px !important;
    font-size: 12px;
    padding: 10px 40px 10px 40px !important;

  }


}

.btnhidesmApp {
  width: 22%;
  background: linear-gradient(204.79deg, #F79433 15.8%, #FF4949 126.22%);;
  color: #FFFFFF;
  margin: auto;
  text-transform: initial;
  border-radius: 50px;
  margin-bottom: 30px;
  margin-top: 10px !important;

}

.btnhidesmAppHeader {

  background: linear-gradient(204.79deg, #F79433 15.8%, #FF4949 126.22%);;
  color: #FFFFFF;
  margin: auto;
  text-transform: initial;
  border-radius: 50px;
  margin-bottom: -20px;
  margin-top: 60px !important;
  width: 400px;

}

@media (max-width: 768px) {
  .btnhidesmApp {
    width: 105vw;
    height: 46px !important;
    background: linear-gradient(204.79deg, #F79433 15.8%, #FF4949 126.22%);;
    color: #FFFFFF;
    margin: auto;
    text-transform: initial;
    border-radius: 0px;
    margin-bottom: -60px !important;
  }

  .btnhidesmAppHeader {
    width: 105vw;
    height: 46px !important;
    background: linear-gradient(204.79deg, #F79433 15.8%, #FF4949 126.22%);;
    color: #FFFFFF;
    margin: auto;
    text-transform: initial;
    border-radius: 0px;
    margin-bottom: -7px !important;
  }
}

.btnServers {
  left: -65px;
  border-radius: 40px;
  color: #fff;
  background: #F79433;
  min-width: 90%;
  min-height: 90%;
  font-size: 18px;
  text-transform: initial;
  font-size: 14px !important;
}

@media (max-width: 768px) {
  .btnServers {
    background: linear-gradient(249.35deg, #F79433 6.62%, #FF4949 95.63%);
    left: -12.5%;
    font-size: 12px;
    text-transform: initial;
  }
}

.btncotSolution {

  background: linear-gradient(249.35deg, #F79433 6.62%, #FF4949 95.63%);
  border-radius: 23px;
  color: #FFFFFF !important;
  text-transform: initial;
  width: 30%;
  height: 24px;
}

@media all and (max-width: 768px) {
  .btncotSolution {
    background: linear-gradient(249.35deg, #F79433 6.62%, #FF4949 95.63%);
    border-radius: 0px;
    color: #FFFFFF !important;
    text-transform: initial;
  //width: 100% !important; height: 46px !important;
    width: 105vw !important;
  }
}
</style>