export default{



    copyrightText: ` © Copyright ${new Date().getFullYear()}. Designed by`,
    googleAnalyticsKey:'G-43XNE11TQB',
    googleAdds:'AW-647429377/ahuzCOqfnuEBEIH627QC',
    // domain : "http://localhost:8000",
    domain : "https://crm.sinntec.com.mx",
    // sitekey:'6Lf5VkopAAAAAPBb_lMKCCUuv9_-FLtK_nc6piau',//localhost
    sitekey:'6Lfphl0pAAAAAEDbdxZf5kKtls3jCCW_BcKhs1uT',  //beta
    //sitekey:'6LdleJIpAAAAABmg8d5M-6lSgtYXxPWzCp_xsuVA',  //prod
    //domain : "https://crm.sinntec.com.mx",//prod
}