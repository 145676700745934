import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/Sinntec',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home"*/ '../views/HomeView.vue'),
    meta: {
      title: 'Sinntec Innovación Tecnológica',
      description: 'Nos convertimos en tu equipo tecnológico a largo plazo para el diseño, desarrollo y mantenimiento de tus sistemas web, escritorio y App.',
    },
  },
  {
    path: '/desarrollo-de-software',
    name: 'desarrollo-de-software',
    component: () => import(/* webpackChunkName: "desarrollo-de-software" */  '../views/pages/desarrollo-de-software.vue'),
    meta: {
      title: 'Desarrollo de software',
      description: 'Somos tu equipo experto en desarrollo de software a la medida, estamos a tu diposición para ayudarte a alcanzar tus objetivos.',
    },
  },
  {
    path: '/desarrollo-de-apps',
    name: 'desarrollo-de-apps',
    component: () => import(/* webpackChunkName: "desarrollo-de-aplicaciones" */  '../views/pages/desarrollo-de-apps.vue'),
    meta: {
      title: 'Desarrollo de Apps',
      description: 'Desarrollo de Apps Nativas o Multiplataforma para tu negocio.',
    },
  },
  {
    path: '/blog-sinntec',
    name: 'blog-sinntec',
    component: () => import(/* webpackChunkName: "blog-sinntec" */  '../views/pages/blog-sinntec.vue'),
    meta: {
      title: 'Sinntec Innovación Tecnológica',
      description: 'Nos convertimos en tu equipo tecnológico a largo plazo para el diseño, desarrollo y mantenimiento de tus sistemas web, escritorio y App.',
    },
  },
  {
    path: '/servidores-virtuales',
    name: 'servidores-virtuales',
    component: () => import(/* webpackChunkName: "servidores-virtuales" */  '../views/pages/servidores-virtuales.vue'),
    meta: {
      title: 'Servidores VPS',
      description: 'Con nuestros servidores VPS confiables y de alta calidad, olvídate de preocupaciones técnicas.',
    },
  },
  {
    path: '/no-page-found',
    name: 'no-page-found',
    component: ()=> import(/* webpackChunkName; "No-page-Found"*/ '../views/pages/no-page-found.vue'),
    meta: {
      title: 'Sinntec Innovación Tecnológica',
      description: 'Nos convertimos en tu equipo tecnológico a largo plazo para el diseño, desarrollo y mantenimiento de tus sistemas web, escritorio y App.',
    },
  },
  {
    path:'/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "Home"*/ '../views/HomeView.vue'),
    meta: {
      title: 'Sinntec Innovación Tecnológica',
      description: 'Nos convertimos en tu equipo tecnológico a largo plazo para el diseño, desarrollo y mantenimiento de tus sistemas web, escritorio y App.',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Imprime la posición guardada si existe

    if (to.path === '/') {

      return { x: 0, y: 0 };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => resolve({ x: 0, y: 0 }), 300);
      });
    }
  }
  
})

router.beforeEach((to, from, next) => {
  const titleDefault = 'Sinntec Innovación Tecnológica';
  const descriptionDefault = 'Nos convertimos en tu equipo tecnológico a largo plazo para el diseño, desarrollo y mantenimiento de tus sistemas web, escritorio y App.';
  document.title = to.meta.title || titleDefault;
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', to.meta.description || descriptionDefault);
  next();
});

export default router
